import Link from '../Link'
import Image from '../Image'
import extractLinkitParts from '../../lib/extractLinkitParts'
import { cn } from '../../lib/cn'

export default function CallToActionGrid( { callsToAction } ) {
  return (
    <div className="flex flex-wrap gap-[1px] flex-col sm:flex-row">
      { callsToAction.map( callToAction => (
        <CallToAction
          key={ callToAction.id }
          copy={ callToAction.ctaCopy }
          ctaText={ callToAction.ctaText }
          link={ callToAction.ctaLink?.link }
          backgroundColor={ callToAction.backgroundColor }
          backgroundImage={ callToAction.backgroundImage?.[ 0 ] }
        />
      ) ) }
    </div>
  )
}

function CallToAction( {
  copy, ctaText, link, backgroundColor, backgroundImage, 
} ) {
  console.log( backgroundColor )
  console.log( backgroundColor === 'white' )
  return (
    <div className="relative grow shrink-0 items-stretch">
      { backgroundImage && (
        <div className="absolute inset-0">
          <Image
            src={ backgroundImage.url }
            alt=""
            layout="fill"
            objectFit="cover"
            sizes="( max-width: 700px ) 87vw, 43.5vw"
          />
        </div>
      ) }
      <Link 
        className={cn(
          'group relative flex items-center justify-center w-full py-[max(3vw,6rem)] bg-black text-white',
          backgroundColor === 'black' && (backgroundImage ? 'bg-black/90' : 'bg-black'),
          backgroundColor === 'red' && (backgroundImage ? 'bg-red/90' : 'bg-red'),
          backgroundColor === 'grey' && (backgroundImage ? 'bg-blueGrey__superlight/90' : 'bg-blueGrey__superlight'),
          backgroundColor === 'white' && (backgroundImage ? 'bg-white/90 text-black' : 'bg-white text-black'),
        )}
        href={ extractLinkitParts( link ).url }
      >
        <div className="text-center flex flex-col items-center justify-center gap-4 max-w-sm">
          <h4 
            className="text-medium prose-strong"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={ { 
              __html: copy.replace( '<p>', '' ).replace( '</p>', '' ),
            } }
          />
          <span className="underline decoration-transparent text-dropped font-general-sans-semi-bold group-hover:decoration-current underline-offset-2 decoration-1 transition-all">{ ctaText }</span>
        </div>
      </Link>
    </div>
  )
}
