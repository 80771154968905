import styled from 'styled-components'
import Stack from '../layout/Stack'
import Container from '../layout/Container'
import { StyledArticleContent } from '../article/ArticleContent'
import { usePrintLinks } from '../PrintLinks'
import { nl2pIfNotRedactored } from '../../lib/strings'
import { useState } from 'react'
import { cn } from '../../lib/cn'
import Chevron from '../icons/Chevron'

function ContentSection( { contentSection } ) {
  const { printLinksRef } = usePrintLinks()

  return (
    <Stack
      bottom={ 4 }
    >
      <Container>
        <StyledContentSection ref={ printLinksRef }>
          <h2>{ contentSection.sectionTitle }</h2>
          <div className="flex flex-col gap-8">
            <StyledContent dangerouslySetInnerHTML={ { __html: contentSection.sectionContent } } />
            {contentSection.accordionItems?.length > 0 && (
              <div className="flex flex-col gap-6">
                {contentSection.accordionItems.map( ( item ) => (
                  <AccordionItem key={ item.id } item={ item } />
                ))}
              </div>
            )}
          </div>
        </StyledContentSection>
      </Container>
    </Stack>
  )
}

function AccordionItem( { item } ) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div key={ item.id }>
      <button
        className="w-full text-left"
        type="button"
        onClick={ () => setIsOpen(!isOpen) }
      >
        <h3 
          className="font-general-sans-semi-bold flex items-center gap-4 cursor-pointer"
          >
          { item.accordionItemTitle }
          <Chevron className={cn('transition-transform size-5 shrink-0 grow-0 ml-auto', isOpen && 'rotate-90')} />
        </h3>
      </button>
      <div 
        className={cn('grid grid-rows-[0fr] transition-all overflow-hidden', isOpen && 'grid-rows-[1fr]')}
        inert={ !isOpen }
      >
        <div className="min-h-0">
          <div 
            className="paras min-h-0 pt-4"
            dangerouslySetInnerHTML={ { __html: nl2pIfNotRedactored(item.accordionItemBody) } } 
          />
          {item.readMoreLink && (
            <a
              className="font-general-sans-semi-bold underline underline-offset-3 text-sm mt-4 inline-block min-h-0"
              href={ item.readMoreLink } 
              data-skip-print-links
            >
              Read more
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

const StyledContentSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem -1rem;

  > * {
    box-sizing: border-box;
    padding: 0.5rem 1rem;

    &:first-child {
      flex: 1 0 33.333%;
    }
    
    &:last-child {
      flex: 1 0 66.666%;
      min-inline-size: 28rem;

      > div {
        max-width: 40rem;
      }

      @media ( max-width: 35rem ) {
        min-inline-size: 100%;
      }
    }
  }

  h2 {
    margin: 0;
    ${ p => p.theme.typo.generalSansSemiBold }
    font-size: ${ p => p.theme.typo.sizes.medium };
    color: ${ p => p.theme.colors.red };
  }
`

const StyledContent = styled( StyledArticleContent )`
  > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  
  .fu-c-content__large-text {
    font-size: ${ p => p.theme.typo.sizes.medium };
  }
`

export default ContentSection
