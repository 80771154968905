import Image from "../Image";
import Container from "../layout/Container";

export default function FullWidthImage({ image }) {
  return (
    <Container fullWidth>
      <div className="w-full">
        <Image
          src={image.url}
          alt={image.imageAltText}
          width={image.width}
          height={image.height}
        />
      </div>
    </Container>
  )
}