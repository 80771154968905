import styled from 'styled-components'
import Container from '../layout/Container'
import Image from '../Image'
import { cn } from '../../lib/cn'

function Cards( { sectionTitle, cards } ) {
  return (
    <Container>
      <div className="pb-16">
        { sectionTitle && (
          <h2 className="text-red font-general-sans-semi-bold text-medium mb-8">
            { sectionTitle }
          </h2>
        ) }
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          { cards.map( card => (
            <Card
              key={ card.id }
              card={ card }
            />
          ) )}
        </div>
      </div>
    </Container>
  )
}

function Card( { card } ) {
  const image = card.cardImage?.[ 0 ]

  const Component = ({children, className}) => {
    if ( card.cardLink?.link ) {
      return (
        <a 
          className={ className }
          href={ card.cardLink?.link } 
          target={ card.cardLink?.target }
        >
          { children }
        </a>
      )
    }

    return <div className={ className }>{ children }</div>
  }

  return (
    <Component className="group block relative">
      { image && (
        <div className="relative aspect-card">
          <Image
            className="object-cover"
            fill
            src={ image.url }
            alt={ image.imageAltText }
            sizes="( max-width: 749 ) 87vw, calc( 29vw - 4rem )"
          />
        </div>
      ) }
      <div className="md:absolute md:bottom-0 md:left-8 md:max-w-[calc(100%-4rem)] p-6 bg-black md:bg-black/70 text-white">
        <h3 className="font-general-sans-semi-bold mb-4">{ card.cardTitle }</h3>
        <div 
          className={cn('transition-all', card.cardLink?.link && 'group-hover:mb-4')}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={ { __html: card.cardBody } } 
        />
        { card.cardLink?.link && (
          <span className="font-general-sans-semi-bold underline underline-offset-3 decoration-red text-small mt-4 inline-block min-h-0">
            Read more
          </span>
        ) }
      </div>
    </Component>
  )
}

const StyledCard = styled.div`
  h3 {
    margin: 1rem 0;
    ${ p => p.theme.typo.generalSansSemiBold };
    font-size: ${ p => p.theme.typo.sizes.medium };
  }
  
  > div {
    font-variation-settings: 'wght' 300;

    p {
      margin-top: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

interface StyledCardImage {
  $maxWidth: number,
}

const StyledCardImage = styled.div<StyledCardImage>`
  max-width: ${ p => p.$maxWidth }px;
`

export default Cards
