import { cn } from "../../lib/cn"
import Container from "../layout/Container"

export default function NumbersShowcase({
  currentBgColor,
  sectionTitle,
  items,
}) {
  return (
    <Container>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 lg:gap-9 lg:grid-cols-3 pb-16">
        <h2 className={cn('font-general-sans-semi-bold !text-medium', currentBgColor === 'black' && 'text-red')}>{sectionTitle}</h2>
        <div className="grid grid-cols-subgrid gap-6 lg:gap-9 lg:col-span-2">
          {items.map((item) => (
            <div 
              className="max-w-sm"
              key={item.id}
            >
              <h3 className="font-general-sans-semi-bold text-7xl leading-none -mt-[0.25em]">{item.figure}</h3>
              <p className="font-general-sans-regular text-normal mt-4 leading-normal text-pretty">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}